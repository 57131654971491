<div class="slide-toggle-wrapper">
  <mat-slide-toggle [formControl]="control">{{ title }}</mat-slide-toggle>
</div>
<div
  class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align"
>
  <div
    class="mat-mdc-form-field-hint-wrapper ng-trigger ng-trigger-transitionMessages"
    style="opacity: 1; transform: translateY(0%)"
  >
    <div class="mat-mdc-form-field-hint-spacer"></div>
  </div>
</div>
