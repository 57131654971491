/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { GenericResponseString } from '../models/generic-response-string';

@Injectable({ providedIn: 'root' })
export class KeycloakService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `keycloakGetMailAddress()` */
  static readonly KeycloakGetMailAddressPath = '/api/modules/keycloak-ext/keycloak-service/{realm}/user/{ssoId}/mailAddress';

  /**
   * GetMailAddress.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMailAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMailAddress$Response(
    params: {
      realm: string;
      ssoId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GenericResponseString>> {
    const rb = new RequestBuilder(this.rootUrl, KeycloakService.KeycloakGetMailAddressPath, 'get');
    if (params) {
      rb.path('realm', params.realm, {});
      rb.path('ssoId', params.ssoId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenericResponseString>;
      })
    );
  }

  /**
   * GetMailAddress.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMailAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMailAddress(
    params: {
      realm: string;
      ssoId: string;
    },
    context?: HttpContext
  ): Observable<GenericResponseString> {
    return this.getMailAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenericResponseString>): GenericResponseString => r.body)
    );
  }

}
