import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectFormDirective } from './directives/connect-form.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ConnectFormDirective, ConnectFormDirective],
  exports: [ConnectFormDirective],
})
export class UtilsModule {}
