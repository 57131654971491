<mat-form-field appearance="outline" [class.cdk-visually-hidden]="invisible">
  <mat-label *ngIf="hasValue(title)">{{ title }}</mat-label>
  <input matInput [formControl]="control" *ngIf="control" [type]="type" />
  <input
    matInput
    [type]="type"
    [ngModel]="ngModel"
    (ngModelChange)="ngModelChange.emit($event)"
    *ngIf="!control"
  />
</mat-form-field>
