/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BaseResponse } from '../models/base-response';
import { TitleMasterData } from '../models/title-master-data';

@Injectable({ providedIn: 'root' })
export class BildungsloginAdminService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `bildungsloginAdminSaveMedia()` */
  static readonly BildungsloginAdminSaveMediaPath = '/api/modules/bildungslogin-ext/bildungslogin-admin-service/media';

  /**
   * SaveMedia.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveMedia()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveMedia$Response(
    params?: {
      body?: Array<TitleMasterData>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseResponse>> {
    const rb = new RequestBuilder(this.rootUrl, BildungsloginAdminService.BildungsloginAdminSaveMediaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseResponse>;
      })
    );
  }

  /**
   * SaveMedia.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveMedia$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveMedia(
    params?: {
      body?: Array<TitleMasterData>
    },
    context?: HttpContext
  ): Observable<BaseResponse> {
    return this.saveMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseResponse>): BaseResponse => r.body)
    );
  }

  /** Path part for operation `bildungsloginAdminPushLicence()` */
  static readonly BildungsloginAdminPushLicencePath = '/api/modules/bildungslogin-ext/bildungslogin-admin-service/licence';

  /**
   * PushLicence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pushLicence()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  pushLicence$Response(
    params?: {
      body?: Array<number>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseResponse>> {
    const rb = new RequestBuilder(this.rootUrl, BildungsloginAdminService.BildungsloginAdminPushLicencePath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseResponse>;
      })
    );
  }

  /**
   * PushLicence.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pushLicence$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  pushLicence(
    params?: {
      body?: Array<number>
    },
    context?: HttpContext
  ): Observable<BaseResponse> {
    return this.pushLicence$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseResponse>): BaseResponse => r.body)
    );
  }

}
