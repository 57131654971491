/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { LoginResponse } from '../models/login-response';
import { UsernamePasswordRequest } from '../models/username-password-request';

@Injectable({ providedIn: 'root' })
export class LoginService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `login()` */
  static readonly LoginPath = '/api/Login';

  /**
   * Login.
   *
   * Login to API and get authorization token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  login$Response(
    params?: {
      body?: UsernamePasswordRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LoginResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LoginService.LoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponse>;
      })
    );
  }

  /**
   * Login.
   *
   * Login to API and get authorization token
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  login(
    params?: {
      body?: UsernamePasswordRequest
    },
    context?: HttpContext
  ): Observable<LoginResponse> {
    return this.login$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /** Path part for operation `getPublicKey()` */
  static readonly GetPublicKeyPath = '/api/Login/public-key';

  /**
   * GetPublicKey.
   *
   * Get public key to crypt password for secure login.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicKey$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, LoginService.GetPublicKeyPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * GetPublicKey.
   *
   * Get public key to crypt password for secure login.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicKey(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.getPublicKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `secureLogin()` */
  static readonly SecureLoginPath = '/api/Login/secure';

  /**
   * SecureLogin.
   *
   * Login with crypted password. Get our public key and crypt your password.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `secureLogin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  secureLogin$Response(
    params?: {
      body?: UsernamePasswordRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LoginResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LoginService.SecureLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponse>;
      })
    );
  }

  /**
   * SecureLogin.
   *
   * Login with crypted password. Get our public key and crypt your password.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `secureLogin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  secureLogin(
    params?: {
      body?: UsernamePasswordRequest
    },
    context?: HttpContext
  ): Observable<LoginResponse> {
    return this.secureLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

}
