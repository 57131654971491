/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BundleResponse } from '../models/bundle-response';
import { Media } from '../models/media';
import { MediaResponse } from '../models/media-response';

@Injectable({ providedIn: 'root' })
export class BildungsloginService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `bildungsloginGetBundle()` */
  static readonly BildungsloginGetBundlePath = '/api/modules/bildungslogin-ext/bildungslogin-service/bundle/{code}';

  /**
   * GetBundle.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBundle$Response(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BundleResponse>> {
    const rb = new RequestBuilder(this.rootUrl, BildungsloginService.BildungsloginGetBundlePath, 'get');
    if (params) {
      rb.path('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BundleResponse>;
      })
    );
  }

  /**
   * GetBundle.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBundle(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<BundleResponse> {
    return this.getBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<BundleResponse>): BundleResponse => r.body)
    );
  }

  /** Path part for operation `bildungsloginGetMediaById()` */
  static readonly BildungsloginGetMediaByIdPath = '/api/modules/bildungslogin-ext/bildungslogin-service/media/{mediaid}';

  /**
   * GetMediaById.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaById$Response(
    params: {
      mediaid: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Media>> {
    const rb = new RequestBuilder(this.rootUrl, BildungsloginService.BildungsloginGetMediaByIdPath, 'get');
    if (params) {
      rb.path('mediaid', params.mediaid, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Media>;
      })
    );
  }

  /**
   * GetMediaById.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaById(
    params: {
      mediaid: string;
    },
    context?: HttpContext
  ): Observable<Media> {
    return this.getMediaById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Media>): Media => r.body)
    );
  }

  /** Path part for operation `bildungsloginGetMedia()` */
  static readonly BildungsloginGetMediaPath = '/api/modules/bildungslogin-ext/bildungslogin-service/media';

  /**
   * GetMedia.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedia$Response(
    params?: {
      from?: number;
      fragment?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MediaResponse>> {
    const rb = new RequestBuilder(this.rootUrl, BildungsloginService.BildungsloginGetMediaPath, 'get');
    if (params) {
      rb.query('from', params.from, {});
      rb.query('fragment', params.fragment, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaResponse>;
      })
    );
  }

  /**
   * GetMedia.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedia(
    params?: {
      from?: number;
      fragment?: string;
    },
    context?: HttpContext
  ): Observable<MediaResponse> {
    return this.getMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaResponse>): MediaResponse => r.body)
    );
  }

}
