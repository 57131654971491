/* tslint:disable */
/* eslint-disable */
export { ActivateLicenceKeyRequest } from './models/activate-licence-key-request';
export { ActivateLicenceKeyResponse } from './models/activate-licence-key-response';
export { BaseResponse } from './models/base-response';
export { BundleItem } from './models/bundle-item';
export { BundleResponse } from './models/bundle-response';
export { CancelDateRequest } from './models/cancel-date-request';
export { CheckEntitlementResponse } from './models/check-entitlement-response';
export { CheckLicenceKeyResponse } from './models/check-licence-key-response';
export { CreateEntitlementsRequest } from './models/create-entitlements-request';
export { Entitlement } from './models/entitlement';
export { EntitlementAssignment } from './models/entitlement-assignment';
export { EntitlementAssignmentRequest } from './models/entitlement-assignment-request';
export { EntitlementDetails } from './models/entitlement-details';
export { GenericListResponseEntitlement } from './models/generic-list-response-entitlement';
export { GenericPageResponseEntitlement } from './models/generic-page-response-entitlement';
export { GenericResponseEntitlement } from './models/generic-response-entitlement';
export { GenericResponseEntitlementDetails } from './models/generic-response-entitlement-details';
export { GenericResponseString } from './models/generic-response-string';
export { GetEntitledUserResponse } from './models/get-entitled-user-response';
export { GetEntitlementsResponse } from './models/get-entitlements-response';
export { GetInstitutionAccessResponse } from './models/get-institution-access-response';
export { InstitutionAccess } from './models/institution-access';
export { Link } from './models/link';
export { LoginResponse } from './models/login-response';
export { Media } from './models/media';
export { MediaResponse } from './models/media-response';
export { SetEntitlementAssignmentResponse } from './models/set-entitlement-assignment-response';
export { SetEntitlementRequest } from './models/set-entitlement-request';
export { SetEntitlementResponse } from './models/set-entitlement-response';
export { SetInstitutionAccessRequest } from './models/set-institution-access-request';
export { SetInstitutionAccessResponse } from './models/set-institution-access-response';
export { TitleMasterData } from './models/title-master-data';
export { UpdateAssignmentRequest } from './models/update-assignment-request';
export { UpdateEntitlementRequest } from './models/update-entitlement-request';
export { UsernamePasswordRequest } from './models/username-password-request';
export { Variant } from './models/variant';
export { WebServiceState } from './models/web-service-state';
