<div class="toolbar px-3 px-lg-6 pt-3 pb-3">
  <div class="position-relative container-fluid px-0">
    <div class="row align-items-center position-relative">
      <div class="col-sm-7 mb-3 mb-sm-0">
        <h3 class="mb-2">Impressum</h3>
      </div>
    </div>
  </div>
</div>

<div class="content pt-3 px-3 px-lg-6 d-flex flex-column-fluid">
  <div class="container-fluid px-0">
    <h4>Diese Website wird betrieben von:</h4>
    <p>
      <strong>Ernst Klett Sprachen GmbH</strong><br />
      Rotebühlstraße 77<br />
      70178 Stuttgart<br />
      Telefon: 07 11 / 66 72 15 55<br />
      Fax: 07 11 / 66 72 2065
    </p>
    <p>
      E-Mail:
      <a href="mailto:kundenservice@klett-sprachen.de"
        >kundenservice@klett-sprachen.de</a
      >
      <br />
      <a href="https://www.klett-sprachen.de" target="_blank"
        >www.klett-sprachen.de</a
      >
    </p>
    <p>
      Geschäftsführer/Managing Directors: Hendrik Funke, Matthias Rupp <br />
      Verleger/Publisher: Dr.h.c. Michael Klett
    </p>
    <p>
      Inhaltlich verantwortlich gemäß § 18 Abs. 2 Medienstaatsvertrag (MStV):<br />
      Hendrik Funke, Matthias Rupp, Rotebühlstraße 77, 70178 Stuttgart
    </p>
    <p>
      HRB 763 784, Amtsgericht Stuttgart<br />
      USt-ID-Nr. DE 205 824 679
    </p>
    <p>
      <strong
        >Bei Fragen und Anregungen besuchen Sie unser Hilfeportal:
        <a href="https://hilfe.klett-sprachen.de" target="_blank"
          >https://hilfe.klett-sprachen.de</a
        ></strong
      >
    </p>

    <p>
      Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-Verordnung: Die
      Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung
      bereit, die sie unter
      <a href="https://www.ec.europa.eu/consumers/odr" target="_blank"
        >www.ec.europa.eu/consumers/odr</a
      >
      finden. Unsere E-Mail Adresse lautet
      <a href="mailto:kundenservice@klett-sprachen.de"
        >kundenservice@klett-sprachen.de</a
      >. Wir nehmen an einem Streitbeilegungsverfahren vor einer
      Verbraucherschlichtungsstelle nicht teil.
    </p>

    <p>Bitte beachten Sie auch unsere Rechtshinweise zum Haftungsausschluss.</p>

    <p>Die Nutzung für Text und Data Mining (§ 44b UrhG) ist vorbehalten.</p>

    <p>
      Technisches Konzept, Design und Realisierung der Website:
      <a href="https://wwww.bdl.de" target="_blank">www.bdl.de</a>
    </p>
  </div>
</div>
