<div class="content pt-3 px-3 px-lg-6 d-flex flex-column-fluid not-found">
  <div class="container-fluid px-0">
    <div class="row h-100 align-items-center">
      <div class="col-12 col-md-10 col-lg-6 mx-auto">
        <div class="card">
          <div class="card-body p-md-5 p-lg-7">
            <div class="d-flex">
              <h1 class="lh-1 mb-0 flex-shrink-0 me-4">
                <i class="fa-solid fa-bug"></i>
              </h1>
              <div>
                <h3 class="h2">Error 404</h3>
                <p class="fs-6">
                  The page your are trying to reach is not found!
                </p>
                <a routerLink="/" class="btn btn-light btn-sm"
                  >Go back to Dashboard</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
