import { Route } from '@angular/router';
import { AuthGuard } from '../../modules/shared/guard/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ImprintComponent } from './imprint/imprint.component';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';

export const appRoutes: Route[] = [
  /*
  {
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },*/
  {
    path: '404',
    redirectTo: '',
    pathMatch: 'full',
    title: 'Klett Sprachen Lizenzverwaltung',
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/entitlements/my',
    pathMatch: 'full',
    title: 'Klett Sprachen Lizenzverwaltung',
  },
  {
    path: 'imprint',
    component: ImprintComponent,
    title: 'Klett Sprachen Lizenzverwaltung',
    canActivate: [AuthGuard],
  },
  {
    path: 'data-privacy',
    component: DataPrivacyComponent,
    title: 'Klett Sprachen Lizenzverwaltung',
    canActivate: [AuthGuard],
  },
  {
    path: 'entitlements',
    loadChildren: () =>
      import('@external-system/modules/entitlement').then(
        (c) => c.entitlementRoutes
      ),
    canActivate: [AuthGuard],
  },
  { path: '**', component: PageNotFoundComponent },
];
