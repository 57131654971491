<div class="toolbar px-3 px-lg-6 pt-3 pb-3">
  <div class="position-relative container-fluid px-0">
    <div class="row align-items-center position-relative">
      <div class="col-sm-7 mb-3 mb-sm-0">
        <h3 class="mb-2">Datenschutz</h3>
      </div>
    </div>
  </div>
</div>

<div class="content pt-3 px-3 px-lg-6 d-flex flex-column-fluid">
  <div class="container-fluid px-0">
    <h3>Informationen zum Datenschutz („Datenschutzerklärung“)</h3>
    <p>
      Die Ernst Klett Sprachen GmbH (im Folgenden: “Verlag“) nimmt den Schutz
      personenbezogener Daten seiner Kunden sehr ernst. Es ist uns wichtig, dass
      Sie jederzeit auf den Schutz Ihrer personenbezogenen Daten beim Verlag
      vertrauen können und wissen, wie der Verlag Ihre Daten verwendet und dabei
      den Datenschutz gewährleistet. Mit dieser Datenschutzerklärung informieren
      wir Sie über die Verarbeitung personenbezogener Daten (im Folgenden:
      Daten) gemäß der Datenschutzgrundverordnung (DSGVO) durch den Verlag, wenn
      Sie das Lizenzmanagement benutzen
    </p>

    <h3>Inhalt</h3>

    <ol>
      <li>Verantwortliche Stelle</li>
      <li>Kontaktdaten des Datenschutzbeauftragten</li>
      <li>Besuch unserer Webseiten</li>
      <li>Cookies</li>
      <li>Webtracking</li>
      <li>Erstellen eines Benutzerkontos (Registrierung)</li>
      <li>Empfänger von personenbezogenen Daten</li>
      <li>Rechte der Betroffenen</li>
      <li>Verlinkungen zu anderen Anbietern</li>
      <li>Sicherheit Ihrer Daten</li>
    </ol>

    <h3>1. Verantwortliche Stelle</h3>
    <p>
      Ernst Klett Sprachen GmbH <br />
      Rotebühlstr. 77 <br />
      70178 Stuttgart <br />
      Tel.: 0711 / 6672-1555 <br />
      E-Mail: kundenservice@klett-sprachen.de <br />
      Weitere Informationen erhalten Sie auch unter
      https://entitlement.eks.dialogcrm.cloud/impressum
    </p>

    <h3>2. Kontaktdaten des Datenschutzbeauftragten</h3>
    <p>
      Unsere Ansprechpartner zu allen Fragen rund um das Thema Datenschutz
      erreichen Sie unter folgenden Kontaktdaten: <br /><br />

      Herr Michael Mayer <br />
      Wagnerstr. 12 <br />
      73765 Neuhausen <br />
      info@ses-mayer.de <br />
      Tel. +49 (7158) 5294 <br />
    </p>

    <h3>3. Besuch unserer Webseiten</h3>
    <p>
      Wenn Sie unsere Internetseiten besuchen, werden <br />
      (1) die IP-Adresse Ihres Computers oder mobilen Geräts, <br />
      (2) das Datum und Uhrzeit Ihres Besuchs,<br />
      (3) die Namen der von Ihnen aufgerufenen Dateien und die übertragene
      Datenmenge,<br />
      (4) die Art des Betriebssystems und des verwendeten Webbrowsers
      einschließlich Spracheinstellungen sowie<br />
      (5) die Webseite, von der Sie auf unsere Internetseite gelangen
      (Referrer-URL),<br />
      (6) die Webseiten, die vom System des Nutzers über unsere Webseite
      aufgerufen werden<br />
      für 24 Stunden in einer Protokolldatei gespeichert. Die Speicherung dieser
      Daten ist aus technischen Gründen zur Anzeige der Internetseite auf Ihrem
      Gerät und zur Sicherstellung der Systemsicherheit in unserem berechtigten
      Interesse gemäß Art. 6 Abs. 1 lit. f DSGVO erforderlich. Anschließend
      werden die Daten gelöscht.<br />
      Host- und Nutzungsinformationen sowie Diagnoseinformationen Metadaten sind
      für die Bereitstellung unserer Dienste erforderlich. Die Diagnose- und
      Performance Informationen werden nicht automatisch verarbeitet und
      analysiert. Nur bei Fehlern oder Störungen analysiert der Support die
      konkreten Ereignisse.
    </p>
    <h3>4. Cookies</h3>
    <p>
      Wir setzen auf unserer Internetseite Session-Cookies und permanente
      Cookies ein. Cookies sind kleine Dateien, die eine von uns vergebene
      Identifikationsnummer enthalten. Cookies werden beim Aufrufen unserer
      Internetseite auf Ihrem Computer oder mobilen Gerät gespeichert. Rufen Sie
      unsere Internetseite erneut auf, so kann Ihr Computer oder mobiles Gerät
      anhand dieser Identifikationsnummer wiedererkannt werden. Session-Cookies
      erleichtern Ihnen die Navigation durch unsere Internetseite. Sie
      ermöglichen, dass und Sie als registrierter Kunde eingeloggt bleiben,
      selbst wenn Sie nacheinander unterschiedliche Internetseiten aufgerufen
      haben. Session-Cookies werden nach Schließen des Browsers gelöscht.
      Permanente Cookies helfen uns die Gestaltung und die Inhalte unserer
      Website an Ihre Erwartungen anzupassen und Zugriff auf digitale Inhalte zu
      ermöglichen, indem wir Ihr Surf-Verhalten auf unserer Internetseite
      protokollieren. Diese Daten werden pseudonymisiert und nicht gemeinsam mit
      Ihren anderen Daten gespeichert. Permanente Cookies zu Analyse-Zwecken
      werden auf Grundlage von § 25 TTDSG nur mit Ihrer Einwilligung gesetzt.
      Permanente Cookies sind bis zu 6 Monate gültig und werden danach gelöscht.
      In unserem Lizenzmanagement haben wir ausschließlich Cookies der Kategorie
      Notwendig: Diese Cookies sind für den Grundbetrieb der Seite unbedingt
      notwendig, insbesondere für das Login.
    </p>
    <p>
      In dieser Tabelle erhalten Sie einen genauen Überblick, welche Cookies von
      Drittanbietern auf unserer Website verwendet werden:
    </p>

    <table class="table">
      <thead>
        <tr>
          <td>Cookie Name</td>
          <td>Kategorie</td>
          <td>Typ</td>
          <td>Quelle</td>
          <td>Verfall</td>
          <td>Zweck</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>AUTH_SESSION_ID_LEGACY</td>
          <td>Notwendig</td>
          <td>HTTP</td>
          <td>sso.dialogcrm.cloud</td>
          <td>Browser-Session</td>
          <td>
            Wird verwendet, um den Login-Status zu speichern und anzuzeigen.
          </td>
        </tr>
        <tr>
          <td>AUTH_SESSION_ID</td>
          <td>Notwendig</td>
          <td>HTTP</td>
          <td>sso.dialogcrm.cloud</td>
          <td>Browser-Session</td>
          <td>
            Wird verwendet, um den Login-Status zu speichern und anzuzeigen.
          </td>
        </tr>
        <tr>
          <td>KEYCLOAK_IDENTITY_LEGACY</td>
          <td>Notwendig</td>
          <td>HTTP</td>
          <td>sso.dialogcrm.cloud</td>
          <td>Browser-Session</td>
          <td>
            Wird verwendet, um den Login-Status zu speichern und anzuzeigen.
          </td>
        </tr>
        <tr>
          <td>KEYCLOAK_IDENTITY</td>
          <td>Notwendig</td>
          <td>HTTP</td>
          <td>sso.dialogcrm.cloud</td>
          <td>Browser-Session</td>
          <td>
            Wird verwendet, um den Login-Status zu speichern und anzuzeigen.
          </td>
        </tr>
        <tr>
          <td>KEYCLOAK_SESSION_LEGACY</td>
          <td>Notwendig</td>
          <td>HTTP</td>
          <td>sso.dialogcrm.cloud</td>
          <td>5 Minuten</td>
          <td>
            Wird verwendet, um den Login-Status zu speichern und anzuzeigen.
          </td>
        </tr>
        <tr>
          <td>KEYCLOAK_SESSION</td>
          <td>Notwendig</td>
          <td>HTTP</td>
          <td>sso.dialogcrm.cloud</td>
          <td>5 Minuten</td>
          <td>
            Wird verwendet, um den Login-Status zu speichern und anzuzeigen.
          </td>
        </tr>
        <tr>
          <td>KEYCLOAK_REMEMBER_ME</td>
          <td>Notwendig</td>
          <td>HTTP</td>
          <td>sso.dialogcrm.cloud</td>
          <td>12 Monate</td>
          <td>
            Wird verwendet, um den Login-Status zu speichern und anzuzeigen.
          </td>
        </tr>
      </tbody>
    </table>

    <p>
      Da wir ausschließlich für den Betrieb notwendige Cookies verwenden, müssen
      Cookie-Einstellungen weder abgefragt werden noch verändert werden können.
    </p>

    <h3>5. Webtracking</h3>

    <p>Ein Webtracking wird nicht durchgeführt.</p>

    <h3>6. Erstellen eines Benutzerkontos (Registrierung)</h3>
    <p>
      Eine Registrierung ist im Lizenzmanagement nicht möglich. Zugang zum
      Lizenzmanagemeng haben ausschließlich Personen, die sich bereits auf einer
      anderen Website des Verlags (klett-sprachen.de, allango.net,
      derdiedaf.com, deltapublishing.co.uk) registriert haben.
    </p>

    <h3>7. Empfänger von personenbezogenen Daten</h3>
    <p>
      Wir setzen Dienstleister ein, die uns bei unseren Aufgaben unterstützen.
      Dies sind: <br />
      Ernst Klett AG Dieses Unternehmen übernimmt die einheitliche rechtliche
      Beratung zum Zwecke des arbeitsteiligen Zusammenwirkens innerhalb der
      Klett Bildungsgruppe. Rechtsgrundlage dafür ist Art. 6 Abs. 1 Buchstabe f
      DSGVO. In einer Vereinbarung zur gemeinsamen Verantwortlichkeit gemäß Art.
      26 DSGVO haben wir mit dem Unternehmen festgelegt, wer welche
      datenschutzrechtlichen Verpflichtungen erfüllt. Den wesentlichen Inhalt
      dieser Vereinbarung stellen wir Ihnen auf Anforderung zur Verfügung.<br /><br />
      BDL GmbH & Co. KG<br />
      Bahnstr. 1<br />
      65366 Geisenheim<br />
      Technischer Betrieb der Datenbank mit Nutzerdaten in unserem Auftrag, ID
      Management<br /><br />
      Mapp Digital Germany GmbH<br />
      c/o Webtrekk GmbH<br />
      Robert-Koch-Platz 4<br />
      10115 Berlin <br />
      Webanalyse- und Statistikdienst<br /><br />
      EBCONT informatics GmbH<br />
      Poststraße 2-4<br />
      60329 Frankfurt am Main<br />
      Protokolldatei, zur Anzeige der Internetseite und zur Sicherstellung der
      Systemsicherheit
    </p>

    <h3>8. Rechte der Betroffenen</h3>
    <p>
      Werden Ihre Daten von uns verarbeitet, sind Sie Betroffener im Sinne der
      DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen
      zu. Alle diese Rechte können Sie per E-Mail an
      kundenservice@klett-sprachen.de oder an die unter den Ziffern 2 und 3
      genannten Adressen geltend machen. Sie haben nach Art. 15 DSGVO das Recht,
      von uns Auskunft darüber zu verlangen, ob wir und wenn ja, welche Daten
      wir über Sie zu welchen Zwecken gespeichert haben, woher diese Daten
      stammen und an welche Empfänger oder Kategorien von Empfängern diese Daten
      gegebenenfalls weitergegeben werden. Sie haben nach Art. 20 DSGVO das
      Recht, Ihre uns aufgrund einer Einwilligung oder eines Vertrages
      übermittelten Daten zu erhalten, sofern die Verarbeitung automatisiert
      erfolgt. Wenn Sie wünschen und dies technisch möglich ist, übermitteln wir
      diese Daten an einen Dritten. Sie haben ein Recht auf Berichtigung
      und/oder unter Umständen auf Vervollständigung Ihrer Daten gegenüber dem
      Verantwortlichen, sofern die Daten, die Sie betreffen, unrichtig bzw.
      unvollständig sind (Art. 16 DSGVO). Sie haben das Recht, dass wir Ihre
      Daten unter den Voraussetzungen von Art. 17 DSGVO löschen oder nach Art.
      18 DSGVO sperren. Wenn Sie Ihr Kundenkonto beim Verlag löschen, löschen
      wir Ihre Daten, soweit wir nicht zu einer Speicherung zur Erfüllung noch
      nicht abgewickelter Verträge, zur Geltendmachung von Rechtsansprüchen oder
      zur Erfüllung gesetzlicher Verpflichtungen berechtigt sind. Soweit wir
      Daten zur Wahrung unserer Interessen ausschließlich auf Grundlage von Art.
      6 Abs. 1 f) DSGVO verarbeiten, haben Sie das Recht, aus Gründen, die sich
      aus Ihrer besonderen Situation ergeben, der Verarbeitung Ihrer Daten zu
      widersprechen. Legen Sie Widerspruch ein, so werden wir die betroffenen
      Daten nicht mehr verarbeiten, es sei denn, wir können zwingende
      schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
      Rechte und Freiheiten überwiegen oder die Verarbeitung dient der
      Durchsetzung von Rechtsansprüchen.
    </p>
    <p>
      Recht auf Widerruf einer Einwilligung<br />

      Wenn Sie in die Nutzung Ihrer Daten eingewilligt haben, können Sie diese
      Einwilligung jederzeit widerrufen. Der Widerruf kann in einer E-Mail an
      kundenservice@klett-sprachen.de erklärt werden. Durch den Widerruf wird
      die Rechtmäßigkeit der Verarbeitung bis zum Widerruf nicht berührt. Ihre
      Daten werden wir dann löschen, es sei denn, wir sind zur Verarbeitung
      gesetzlich berechtigt oder verpflichtet. Es steht Ihnen das Recht auf
      Beschwerde bei einer Aufsichtsbehörde, insbesondere beim
      Landesbeauftragten für den Datenschutz und die Informationsfreiheit
      Baden-Württemberg, Lautenschlagerstraße 20, 70173 Stuttgart, zu.
    </p>

    <h3>9. Verlinkungen zu anderen Anbietern</h3>

    <p>
      Sofern wir auf unseren Internetseiten mit Hilfe von Links auf
      Internetseiten verweisen, die nicht von uns betrieben werden, sind wir für
      die Einhaltung von Datenschutzbestimmungen auf diesen Internetseiten nicht
      verantwortlich. Bitte erkundigen Sie sich auf den dortigen Webseiten über
      den Datenschutz des Anbieters.
    </p>

    <h3>10. Sicherheit Ihrer Daten</h3>

    <p>
      Personenbezogene Daten, die zwischen Ihnen und uns über unsere
      Internetseiten ausgetauscht werden, werden grundsätzlich über
      verschlüsselte Verbindungen übertragen. Zudem treffen wir technische und
      organisatorische Sicherheitsvorkehrungen, um Ihre durch uns verwalteten
      Daten gegen Manipulationen, Verlust, Zerstörung oder gegen den Zugriff
      unberechtigter Personen zu schützen. Bitte beachten Sie jedoch, dass bei
      einer Kommunikation per E-Mail die vollständige Datensicherheit von uns
      nicht gewährleistet werden kann, so dass wir Ihnen bei vertraulichen
      Informationen den Postweg empfehlen.
    </p>
  </div>
</div>
