/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BaseResponse } from '../models/base-response';
import { Entitlement } from '../models/entitlement';
import { GenericPageResponseEntitlement } from '../models/generic-page-response-entitlement';
import { GenericResponseEntitlement } from '../models/generic-response-entitlement';
import { GenericResponseEntitlementDetails } from '../models/generic-response-entitlement-details';

@Injectable({ providedIn: 'root' })
export class EntitlementAdminService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `entitlementAdminGetEntitlements()` */
  static readonly EntitlementAdminGetEntitlementsPath = '/api/modules/entitlement-ext/entitlement-admin-service/entitlements';

  /**
   * GetEntitlements.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEntitlements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlements$Response(
    params?: {
      searchTerm?: string;
      page?: number;
      pageSize?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GenericPageResponseEntitlement>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementAdminService.EntitlementAdminGetEntitlementsPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenericPageResponseEntitlement>;
      })
    );
  }

  /**
   * GetEntitlements.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEntitlements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlements(
    params?: {
      searchTerm?: string;
      page?: number;
      pageSize?: number;
    },
    context?: HttpContext
  ): Observable<GenericPageResponseEntitlement> {
    return this.getEntitlements$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenericPageResponseEntitlement>): GenericPageResponseEntitlement => r.body)
    );
  }

  /** Path part for operation `entitlementAdminGetEntitlement()` */
  static readonly EntitlementAdminGetEntitlementPath = '/api/modules/entitlement-ext/entitlement-admin-service/entitlement/{id}';

  /**
   * GetEntitlement.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEntitlement()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlement$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GenericResponseEntitlement>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementAdminService.EntitlementAdminGetEntitlementPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenericResponseEntitlement>;
      })
    );
  }

  /**
   * GetEntitlement.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEntitlement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlement(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<GenericResponseEntitlement> {
    return this.getEntitlement$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenericResponseEntitlement>): GenericResponseEntitlement => r.body)
    );
  }

  /** Path part for operation `entitlementAdminDeleteEntitlement()` */
  static readonly EntitlementAdminDeleteEntitlementPath = '/api/modules/entitlement-ext/entitlement-admin-service/entitlement/{id}';

  /**
   * DeleteEntitlement.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEntitlement()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEntitlement$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementAdminService.EntitlementAdminDeleteEntitlementPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseResponse>;
      })
    );
  }

  /**
   * DeleteEntitlement.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEntitlement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEntitlement(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<BaseResponse> {
    return this.deleteEntitlement$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseResponse>): BaseResponse => r.body)
    );
  }

  /** Path part for operation `entitlementAdminUpdateEntitlement()` */
  static readonly EntitlementAdminUpdateEntitlementPath = '/api/modules/entitlement-ext/entitlement-admin-service/entitlement';

  /**
   * UpdateEntitlement.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEntitlement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateEntitlement$Response(
    params?: {
      body?: Entitlement
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GenericResponseEntitlement>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementAdminService.EntitlementAdminUpdateEntitlementPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenericResponseEntitlement>;
      })
    );
  }

  /**
   * UpdateEntitlement.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateEntitlement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateEntitlement(
    params?: {
      body?: Entitlement
    },
    context?: HttpContext
  ): Observable<GenericResponseEntitlement> {
    return this.updateEntitlement$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenericResponseEntitlement>): GenericResponseEntitlement => r.body)
    );
  }

  /** Path part for operation `entitlementAdminDeleteEntitlements()` */
  static readonly EntitlementAdminDeleteEntitlementsPath = '/api/modules/entitlement-ext/entitlement-admin-service/entitlement';

  /**
   * DeleteEntitlements.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEntitlements()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteEntitlements$Response(
    params?: {
      body?: Array<number>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementAdminService.EntitlementAdminDeleteEntitlementsPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseResponse>;
      })
    );
  }

  /**
   * DeleteEntitlements.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEntitlements$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteEntitlements(
    params?: {
      body?: Array<number>
    },
    context?: HttpContext
  ): Observable<BaseResponse> {
    return this.deleteEntitlements$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseResponse>): BaseResponse => r.body)
    );
  }

  /** Path part for operation `entitlementAdminGetEntitlementDetails()` */
  static readonly EntitlementAdminGetEntitlementDetailsPath = '/api/modules/entitlement-ext/entitlement-admin-service/entitlement/details/{ssoId}';

  /**
   * GetEntitlementDetails.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEntitlementDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlementDetails$Response(
    params: {
      ssoId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GenericResponseEntitlementDetails>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementAdminService.EntitlementAdminGetEntitlementDetailsPath, 'get');
    if (params) {
      rb.path('ssoId', params.ssoId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenericResponseEntitlementDetails>;
      })
    );
  }

  /**
   * GetEntitlementDetails.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEntitlementDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlementDetails(
    params: {
      ssoId: string;
    },
    context?: HttpContext
  ): Observable<GenericResponseEntitlementDetails> {
    return this.getEntitlementDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenericResponseEntitlementDetails>): GenericResponseEntitlementDetails => r.body)
    );
  }

}
