/**
 * Check if given value is an array
 * @param data
 * @returns {boolean}
 */
export function isArray(data: never): boolean {
  return Object.prototype.toString.call(data) === '[object Array]';
}

/**
 * Check if given value is null or undefined
 * @param value
 * @returns {boolean}
 */
export function isNullOrUndefined(value: unknown): boolean {
  return value === null || value === undefined;
}

export function hasValue(value: unknown | undefined): boolean {
  return !isNullOrUndefined(value);
}

export function hasValues(values: unknown[] | undefined): boolean {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isNullOrUndefined(values) || values.length === 0) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  for (const value of values) {
    if (isNullOrUndefined(value)) return false;
  }
  return true;
}

export function isNullOrEmpty(value: any | undefined): boolean {
  return (
    isNullOrUndefined(value) || (hasValue(value.length) && value.length === 0)
  );
}

export function isNotNullOrEmpty(value: unknown): boolean {
  return !isNullOrEmpty(value);
}

export function compareObjects(
  obj1: any,
  obj2: any,
  excludeProperties?: string[],
  compareProperties?: string[]
): boolean {
  // if (hasValue(excludeProperties) && excludeProperties.includes('__ngContext__')) {
  //   excludeProperties = [...excludeProperties, '__ngContext__']
  // } else {
  //   excludeProperties = ['__ngContext__'];
  // }
  // if both are undefined
  if (isNullOrUndefined(obj1) && isNullOrUndefined(obj2)) {
    return true;
  }

  // if only one is undefined
  if (
    (isNullOrUndefined(obj1) && hasValue(obj2)) ||
    (isNullOrUndefined(obj2) && hasValue(obj1))
  ) {
    return false;
  }

  // compare arrays recursively
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    } else {
      for (let i = 0; i < obj1.length; i++) {
        if (
          !compareObjects(
            obj1[i],
            obj2[i],
            excludeProperties,
            compareProperties
          )
        ) {
          return false;
        }
      }
      return true;
    }
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2;
  }

  if (Object.keys(obj1).length === 0 || Object.keys(obj2).length === 0) {
    return obj1 === obj2;
  }

  //Loop through properties in object 1
  for (const p in obj1) {
    if (includePropertyInCheck(p, excludeProperties, compareProperties)) {
      // Check property exists on both objects
      if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

      switch (typeof obj1[p]) {
        // Deep compare objects
        case 'object':
        case 'undefined':
          if (
            !compareObjects(
              obj1[p],
              obj2[p],
              excludeProperties,
              compareProperties
            )
          )
            return false;
          break;
        // Compare function code
        case 'function':
          if (
            typeof obj2[p] === 'undefined' ||
            (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())
          )
            return false;
          break;
        // Compare values
        default:
          if (obj1[p] !== obj2[p]) {
            return false;
          }
      }
    }
  }

  //Check object 2 for any extra properties
  for (const p in obj2) {
    if (!obj1.hasOwnProperty(p)) {
      if (includePropertyInCheck(p, excludeProperties, compareProperties)) {
        if (typeof obj1[p] === 'undefined') return false;
      }
    }
  }

  return true;
}

function includePropertyInCheck(
  p: string,
  excludeProperties?: string[],
  compareProperties?: string[]
): boolean {
  if (p === '__ngContext__') {
    return false;
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (hasValues(excludeProperties) && excludeProperties.includes(p)) {
      return false;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (hasValues(compareProperties) && !compareProperties.includes(p)) {
        return false;
      } else {
        return true;
      }
    }
  }
}

export function getGUID(): string {
  let d = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}
