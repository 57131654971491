<h1 mat-dialog-title *ngIf="data.headline">{{ data.headline }}</h1>
<div mat-dialog-content>
  <p *ngIf="data.description">{{ data.description }}</p>
  <input matInput [(ngModel)]="inputValue" [placeholder]="data.placeholder" />
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Abbrechen</button>
  <button mat-button [mat-dialog-close]="inputValue" cdkFocusInitial>
    Speichern
  </button>
</div>
