/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ActivateLicenceKeyRequest } from '../models/activate-licence-key-request';
import { ActivateLicenceKeyResponse } from '../models/activate-licence-key-response';
import { BaseResponse } from '../models/base-response';
import { CancelDateRequest } from '../models/cancel-date-request';
import { CheckEntitlementResponse } from '../models/check-entitlement-response';
import { CheckLicenceKeyResponse } from '../models/check-licence-key-response';
import { CreateEntitlementsRequest } from '../models/create-entitlements-request';
import { EntitlementAssignment } from '../models/entitlement-assignment';
import { EntitlementAssignmentRequest } from '../models/entitlement-assignment-request';
import { GenericListResponseEntitlement } from '../models/generic-list-response-entitlement';
import { GenericResponseEntitlement } from '../models/generic-response-entitlement';
import { GetEntitledUserResponse } from '../models/get-entitled-user-response';
import { GetEntitlementsResponse } from '../models/get-entitlements-response';
import { GetInstitutionAccessResponse } from '../models/get-institution-access-response';
import { SetEntitlementAssignmentResponse } from '../models/set-entitlement-assignment-response';
import { SetEntitlementRequest } from '../models/set-entitlement-request';
import { SetEntitlementResponse } from '../models/set-entitlement-response';
import { SetInstitutionAccessRequest } from '../models/set-institution-access-request';
import { SetInstitutionAccessResponse } from '../models/set-institution-access-response';
import { UpdateAssignmentRequest } from '../models/update-assignment-request';
import { UpdateEntitlementRequest } from '../models/update-entitlement-request';

@Injectable({ providedIn: 'root' })
export class EntitlementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `entitlementCheckEntitlement()` */
  static readonly EntitlementCheckEntitlementPath = '/api/modules/entitlement-ext/entitlement-service/check-entitlement/{ssoId}/{product}/{guid}';

  /**
   * CheckEntitlement.
   *
   * Prüft ob ein User auf einen Artikel berechtigt ist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkEntitlement()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkEntitlement$Response(
    params: {
      ssoId: string;
      product: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CheckEntitlementResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementCheckEntitlementPath, 'get');
    if (params) {
      rb.path('ssoId', params.ssoId, {});
      rb.path('product', params.product, {});
      rb.path('guid', params.guid, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckEntitlementResponse>;
      })
    );
  }

  /**
   * CheckEntitlement.
   *
   * Prüft ob ein User auf einen Artikel berechtigt ist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkEntitlement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkEntitlement(
    params: {
      ssoId: string;
      product: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<CheckEntitlementResponse> {
    return this.checkEntitlement$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckEntitlementResponse>): CheckEntitlementResponse => r.body)
    );
  }

  /** Path part for operation `entitlementGetEntitlementsToSso()` */
  static readonly EntitlementGetEntitlementsToSsoPath = '/api/modules/entitlement-ext/entitlement-service/get-entitlement-to-sso/{ssoId}/{guid}';

  /**
   * GetEntitlementsToSso.
   *
   * Lädt alle Entitlements zu einem SSO-User
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEntitlementsToSso()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlementsToSso$Response(
    params: {
      ssoId: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetEntitlementsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementGetEntitlementsToSsoPath, 'get');
    if (params) {
      rb.path('ssoId', params.ssoId, {});
      rb.path('guid', params.guid, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetEntitlementsResponse>;
      })
    );
  }

  /**
   * GetEntitlementsToSso.
   *
   * Lädt alle Entitlements zu einem SSO-User
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEntitlementsToSso$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlementsToSso(
    params: {
      ssoId: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<GetEntitlementsResponse> {
    return this.getEntitlementsToSso$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEntitlementsResponse>): GetEntitlementsResponse => r.body)
    );
  }

  /** Path part for operation `entitlementGetEntitlementsToInstitution()` */
  static readonly EntitlementGetEntitlementsToInstitutionPath = '/api/modules/entitlement-ext/entitlement-service/get-entitlement-to-institution/{institutionId}/{guid}';

  /**
   * GetEntitlementsToInstitution.
   *
   * Lädt alle Entitlements zu einer Institution
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEntitlementsToInstitution()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlementsToInstitution$Response(
    params: {
      institutionId: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetEntitlementsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementGetEntitlementsToInstitutionPath, 'get');
    if (params) {
      rb.path('institutionId', params.institutionId, {});
      rb.path('guid', params.guid, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetEntitlementsResponse>;
      })
    );
  }

  /**
   * GetEntitlementsToInstitution.
   *
   * Lädt alle Entitlements zu einer Institution
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEntitlementsToInstitution$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlementsToInstitution(
    params: {
      institutionId: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<GetEntitlementsResponse> {
    return this.getEntitlementsToInstitution$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEntitlementsResponse>): GetEntitlementsResponse => r.body)
    );
  }

  /** Path part for operation `entitlementGetEntitlementAssignmentsToSso()` */
  static readonly EntitlementGetEntitlementAssignmentsToSsoPath = '/api/modules/entitlement-ext/entitlement-service/get-entitlement-assignments-to-sso/{ssoId}/{guid}';

  /**
   * GetEntitlementAssignmentsToSso.
   *
   * Lädt alle Entitlments inkl. Assignments  zu einem SSO-Account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEntitlementAssignmentsToSso()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlementAssignmentsToSso$Response(
    params: {
      ssoId: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetEntitlementsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementGetEntitlementAssignmentsToSsoPath, 'get');
    if (params) {
      rb.path('ssoId', params.ssoId, {});
      rb.path('guid', params.guid, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetEntitlementsResponse>;
      })
    );
  }

  /**
   * GetEntitlementAssignmentsToSso.
   *
   * Lädt alle Entitlments inkl. Assignments  zu einem SSO-Account
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEntitlementAssignmentsToSso$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlementAssignmentsToSso(
    params: {
      ssoId: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<GetEntitlementsResponse> {
    return this.getEntitlementAssignmentsToSso$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEntitlementsResponse>): GetEntitlementsResponse => r.body)
    );
  }

  /** Path part for operation `entitlementGetActiveEntitlementAssignmentsToSso()` */
  static readonly EntitlementGetActiveEntitlementAssignmentsToSsoPath = '/api/modules/entitlement-ext/entitlement-service/active-entitlement-assignments-to-sso/{ssoId}/{guid}';

  /**
   * GetActiveEntitlementAssignmentsToSso.
   *
   * Lädt alle aktiven (aktuell gültigen) Assignments inkl. den Entitlments zu einem SSO-Account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveEntitlementAssignmentsToSso()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveEntitlementAssignmentsToSso$Response(
    params: {
      ssoId: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetEntitlementsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementGetActiveEntitlementAssignmentsToSsoPath, 'get');
    if (params) {
      rb.path('ssoId', params.ssoId, {});
      rb.path('guid', params.guid, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetEntitlementsResponse>;
      })
    );
  }

  /**
   * GetActiveEntitlementAssignmentsToSso.
   *
   * Lädt alle aktiven (aktuell gültigen) Assignments inkl. den Entitlments zu einem SSO-Account
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveEntitlementAssignmentsToSso$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveEntitlementAssignmentsToSso(
    params: {
      ssoId: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<GetEntitlementsResponse> {
    return this.getActiveEntitlementAssignmentsToSso$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEntitlementsResponse>): GetEntitlementsResponse => r.body)
    );
  }

  /** Path part for operation `entitlementGetEntitlementsByOrderReference()` */
  static readonly EntitlementGetEntitlementsByOrderReferencePath = '/api/modules/entitlement-ext/entitlement-service/entitlements/{ssoId}/order/{orderReference}';

  /**
   * GetEntitlementsByOrderReference.
   *
   * Lädt alle Entitlments inkl. Assignments zu einer Order-Reference
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEntitlementsByOrderReference()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlementsByOrderReference$Response(
    params: {
      ssoId: string;
      orderReference: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetEntitlementsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementGetEntitlementsByOrderReferencePath, 'get');
    if (params) {
      rb.path('ssoId', params.ssoId, {});
      rb.path('orderReference', params.orderReference, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetEntitlementsResponse>;
      })
    );
  }

  /**
   * GetEntitlementsByOrderReference.
   *
   * Lädt alle Entitlments inkl. Assignments zu einer Order-Reference
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEntitlementsByOrderReference$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitlementsByOrderReference(
    params: {
      ssoId: string;
      orderReference: string;
    },
    context?: HttpContext
  ): Observable<GetEntitlementsResponse> {
    return this.getEntitlementsByOrderReference$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEntitlementsResponse>): GetEntitlementsResponse => r.body)
    );
  }

  /** Path part for operation `entitlementGetEntitledAssignments()` */
  static readonly EntitlementGetEntitledAssignmentsPath = '/api/modules/entitlement-ext/entitlement-service/get-entitlement-to-sso/{ssoId}/{product}/{guid}';

  /**
   * GetEntitledAssignments.
   *
   * Lädt alle Entitlement-Assignments zu einem User und Produkt
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEntitledAssignments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitledAssignments$Response(
    params: {
      ssoId: string;
      product: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetEntitledUserResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementGetEntitledAssignmentsPath, 'get');
    if (params) {
      rb.path('ssoId', params.ssoId, {});
      rb.path('product', params.product, {});
      rb.path('guid', params.guid, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetEntitledUserResponse>;
      })
    );
  }

  /**
   * GetEntitledAssignments.
   *
   * Lädt alle Entitlement-Assignments zu einem User und Produkt
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEntitledAssignments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEntitledAssignments(
    params: {
      ssoId: string;
      product: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<GetEntitledUserResponse> {
    return this.getEntitledAssignments$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEntitledUserResponse>): GetEntitledUserResponse => r.body)
    );
  }

  /** Path part for operation `entitlementSetEntitlementAssignment()` */
  static readonly EntitlementSetEntitlementAssignmentPath = '/api/modules/entitlement-ext/entitlement-service/set-entitlement-assignment';

  /**
   * SetEntitlementAssignment.
   *
   * Erstellt oder verändert ein Entitlement-Assignment zu einem Entitlement. Hiermit wird eine Lizenz einem User zugeordnet bzw. an einen anderen User umgehängt.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setEntitlementAssignment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setEntitlementAssignment$Response(
    params?: {
      body?: EntitlementAssignmentRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SetEntitlementAssignmentResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementSetEntitlementAssignmentPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetEntitlementAssignmentResponse>;
      })
    );
  }

  /**
   * SetEntitlementAssignment.
   *
   * Erstellt oder verändert ein Entitlement-Assignment zu einem Entitlement. Hiermit wird eine Lizenz einem User zugeordnet bzw. an einen anderen User umgehängt.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setEntitlementAssignment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setEntitlementAssignment(
    params?: {
      body?: EntitlementAssignmentRequest
    },
    context?: HttpContext
  ): Observable<SetEntitlementAssignmentResponse> {
    return this.setEntitlementAssignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetEntitlementAssignmentResponse>): SetEntitlementAssignmentResponse => r.body)
    );
  }

  /** Path part for operation `entitlementDeleteEntitlementAssignment()` */
  static readonly EntitlementDeleteEntitlementAssignmentPath = '/api/modules/entitlement-ext/entitlement-service/delete-entitlement-assignment/{entitlementId}/{assignmentId}';

  /**
   * DeleteEntitlementAssignment.
   *
   * Löscht ein Entitlement-Assignment und gibt die Lizenz damit wieder frei.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEntitlementAssignment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEntitlementAssignment$Response(
    params: {
      entitlementId: number;
      assignmentId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementDeleteEntitlementAssignmentPath, 'delete');
    if (params) {
      rb.path('entitlementId', params.entitlementId, {});
      rb.path('assignmentId', params.assignmentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseResponse>;
      })
    );
  }

  /**
   * DeleteEntitlementAssignment.
   *
   * Löscht ein Entitlement-Assignment und gibt die Lizenz damit wieder frei.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEntitlementAssignment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEntitlementAssignment(
    params: {
      entitlementId: number;
      assignmentId: number;
    },
    context?: HttpContext
  ): Observable<BaseResponse> {
    return this.deleteEntitlementAssignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseResponse>): BaseResponse => r.body)
    );
  }

  /** Path part for operation `entitlementSetInstitutionAccess()` */
  static readonly EntitlementSetInstitutionAccessPath = '/api/modules/entitlement-ext/entitlement-service/set-institution-access';

  /**
   * SetInstitutionAccess.
   *
   * Erzeugt den Zugriff auf die Entitlements einer Institution für ein SSO-User.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setInstitutionAccess()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setInstitutionAccess$Response(
    params?: {
      body?: SetInstitutionAccessRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SetInstitutionAccessResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementSetInstitutionAccessPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetInstitutionAccessResponse>;
      })
    );
  }

  /**
   * SetInstitutionAccess.
   *
   * Erzeugt den Zugriff auf die Entitlements einer Institution für ein SSO-User.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setInstitutionAccess$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setInstitutionAccess(
    params?: {
      body?: SetInstitutionAccessRequest
    },
    context?: HttpContext
  ): Observable<SetInstitutionAccessResponse> {
    return this.setInstitutionAccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetInstitutionAccessResponse>): SetInstitutionAccessResponse => r.body)
    );
  }

  /** Path part for operation `entitlementGetInstitutionAccessToInstitution()` */
  static readonly EntitlementGetInstitutionAccessToInstitutionPath = '/api/modules/entitlement-ext/entitlement-service/get-institution-access-to-institution/{institutionId}/{guid}';

  /**
   * GetInstitutionAccessToInstitution.
   *
   * Gibt alle hinterlegten Zugriffe einer Institution zurück
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInstitutionAccessToInstitution()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInstitutionAccessToInstitution$Response(
    params: {
      institutionId: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetInstitutionAccessResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementGetInstitutionAccessToInstitutionPath, 'get');
    if (params) {
      rb.path('institutionId', params.institutionId, {});
      rb.path('guid', params.guid, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetInstitutionAccessResponse>;
      })
    );
  }

  /**
   * GetInstitutionAccessToInstitution.
   *
   * Gibt alle hinterlegten Zugriffe einer Institution zurück
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInstitutionAccessToInstitution$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInstitutionAccessToInstitution(
    params: {
      institutionId: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<GetInstitutionAccessResponse> {
    return this.getInstitutionAccessToInstitution$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetInstitutionAccessResponse>): GetInstitutionAccessResponse => r.body)
    );
  }

  /** Path part for operation `entitlementGetInstitutionAccessToSso()` */
  static readonly EntitlementGetInstitutionAccessToSsoPath = '/api/modules/entitlement-ext/entitlement-service/get-institution-access-to-sso/{ssoId}/{guid}';

  /**
   * GetInstitutionAccessToSso.
   *
   * Gibt alle hinterlegten Zugriffe eines SSO-Users zurück
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInstitutionAccessToSso()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInstitutionAccessToSso$Response(
    params: {
      ssoId: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetInstitutionAccessResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementGetInstitutionAccessToSsoPath, 'get');
    if (params) {
      rb.path('ssoId', params.ssoId, {});
      rb.path('guid', params.guid, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetInstitutionAccessResponse>;
      })
    );
  }

  /**
   * GetInstitutionAccessToSso.
   *
   * Gibt alle hinterlegten Zugriffe eines SSO-Users zurück
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInstitutionAccessToSso$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInstitutionAccessToSso(
    params: {
      ssoId: string;
      guid: string;
    },
    context?: HttpContext
  ): Observable<GetInstitutionAccessResponse> {
    return this.getInstitutionAccessToSso$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetInstitutionAccessResponse>): GetInstitutionAccessResponse => r.body)
    );
  }

  /** Path part for operation `entitlementSetEntitlement()` */
  static readonly EntitlementSetEntitlementPath = '/api/modules/entitlement-ext/entitlement-service/set-entitlement';

  /**
   * SetEntitlement.
   *
   * Erstellt oder ändert ein Entitlement.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setEntitlement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setEntitlement$Response(
    params?: {
      body?: SetEntitlementRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SetEntitlementResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementSetEntitlementPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetEntitlementResponse>;
      })
    );
  }

  /**
   * SetEntitlement.
   *
   * Erstellt oder ändert ein Entitlement.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setEntitlement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setEntitlement(
    params?: {
      body?: SetEntitlementRequest
    },
    context?: HttpContext
  ): Observable<SetEntitlementResponse> {
    return this.setEntitlement$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetEntitlementResponse>): SetEntitlementResponse => r.body)
    );
  }

  /** Path part for operation `entitlementCreateEntitlements()` */
  static readonly EntitlementCreateEntitlementsPath = '/api/modules/entitlement-ext/entitlement-service/entitlements';

  /**
   * CreateEntitlements.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEntitlements()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createEntitlements$Response(
    params?: {
      body?: CreateEntitlementsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GenericListResponseEntitlement>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementCreateEntitlementsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenericListResponseEntitlement>;
      })
    );
  }

  /**
   * CreateEntitlements.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createEntitlements$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createEntitlements(
    params?: {
      body?: CreateEntitlementsRequest
    },
    context?: HttpContext
  ): Observable<GenericListResponseEntitlement> {
    return this.createEntitlements$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenericListResponseEntitlement>): GenericListResponseEntitlement => r.body)
    );
  }

  /** Path part for operation `entitlementCheckLicenceKeyWithRole()` */
  static readonly EntitlementCheckLicenceKeyWithRolePath = '/api/modules/entitlement-ext/entitlement-service/check-licence-key/{licenceKey}/{role}';

  /**
   * CheckLicenceKeyWithRole.
   *
   * Überprüft einen Lizenzschlüssel auf Gültigkeit und liefert ggf. die freie Lizenz zurück.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkLicenceKeyWithRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLicenceKeyWithRole$Response(
    params: {
      licenceKey: string;
      role: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CheckLicenceKeyResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementCheckLicenceKeyWithRolePath, 'get');
    if (params) {
      rb.path('licenceKey', params.licenceKey, {});
      rb.path('role', params.role, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckLicenceKeyResponse>;
      })
    );
  }

  /**
   * CheckLicenceKeyWithRole.
   *
   * Überprüft einen Lizenzschlüssel auf Gültigkeit und liefert ggf. die freie Lizenz zurück.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkLicenceKeyWithRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLicenceKeyWithRole(
    params: {
      licenceKey: string;
      role: string;
    },
    context?: HttpContext
  ): Observable<CheckLicenceKeyResponse> {
    return this.checkLicenceKeyWithRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckLicenceKeyResponse>): CheckLicenceKeyResponse => r.body)
    );
  }

  /** Path part for operation `entitlementCheckLicenceKey()` */
  static readonly EntitlementCheckLicenceKeyPath = '/api/modules/entitlement-ext/entitlement-service/check-licence-key/{licenceKey}';

  /**
   * CheckLicenceKey.
   *
   * Überprüft einen Lizenzschlüssel auf Gültigkeit und liefert ggf. die freie Lizenz zurück.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkLicenceKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLicenceKey$Response(
    params: {
      licenceKey: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CheckLicenceKeyResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementCheckLicenceKeyPath, 'get');
    if (params) {
      rb.path('licenceKey', params.licenceKey, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckLicenceKeyResponse>;
      })
    );
  }

  /**
   * CheckLicenceKey.
   *
   * Überprüft einen Lizenzschlüssel auf Gültigkeit und liefert ggf. die freie Lizenz zurück.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkLicenceKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLicenceKey(
    params: {
      licenceKey: string;
    },
    context?: HttpContext
  ): Observable<CheckLicenceKeyResponse> {
    return this.checkLicenceKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckLicenceKeyResponse>): CheckLicenceKeyResponse => r.body)
    );
  }

  /** Path part for operation `entitlementActivateLicenceKey()` */
  static readonly EntitlementActivateLicenceKeyPath = '/api/modules/entitlement-ext/entitlement-service/activate-licence-key';

  /**
   * ActivateLicenceKey.
   *
   * Activiert eine Lizenz und schreibt ein Assignment zum übergebenen Sso-Acccount. Ist die Lizenz bereits zugewiesen oder nicht verfügbar, wird ein Fehler zurückgeliefert.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateLicenceKey()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activateLicenceKey$Response(
    params?: {
      body?: ActivateLicenceKeyRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActivateLicenceKeyResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementActivateLicenceKeyPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivateLicenceKeyResponse>;
      })
    );
  }

  /**
   * ActivateLicenceKey.
   *
   * Activiert eine Lizenz und schreibt ein Assignment zum übergebenen Sso-Acccount. Ist die Lizenz bereits zugewiesen oder nicht verfügbar, wird ein Fehler zurückgeliefert.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activateLicenceKey$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activateLicenceKey(
    params?: {
      body?: ActivateLicenceKeyRequest
    },
    context?: HttpContext
  ): Observable<ActivateLicenceKeyResponse> {
    return this.activateLicenceKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivateLicenceKeyResponse>): ActivateLicenceKeyResponse => r.body)
    );
  }

  /** Path part for operation `entitlementUpdateEntitlement()` */
  static readonly EntitlementUpdateEntitlementPath = '/api/modules/entitlement-ext/entitlement-service/entitlement';

  /**
   * UpdateEntitlement.
   *
   * Setzt das Ablaufdatum eines Entitlements neu.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEntitlement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateEntitlement$Response(
    params?: {
      body?: UpdateEntitlementRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GenericResponseEntitlement>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementUpdateEntitlementPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenericResponseEntitlement>;
      })
    );
  }

  /**
   * UpdateEntitlement.
   *
   * Setzt das Ablaufdatum eines Entitlements neu.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateEntitlement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateEntitlement(
    params?: {
      body?: UpdateEntitlementRequest
    },
    context?: HttpContext
  ): Observable<GenericResponseEntitlement> {
    return this.updateEntitlement$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenericResponseEntitlement>): GenericResponseEntitlement => r.body)
    );
  }

  /** Path part for operation `entitlementUpdateAssignment()` */
  static readonly EntitlementUpdateAssignmentPath = '/api/modules/entitlement-ext/entitlement-service/assignment/{id}';

  /**
   * UpdateAssignment.
   *
   * Setzt das Ablaufdatum eines Assignment neu.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAssignment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAssignment$Response(
    params: {
      id: number;
      body?: UpdateAssignmentRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GenericResponseEntitlement>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementUpdateAssignmentPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenericResponseEntitlement>;
      })
    );
  }

  /**
   * UpdateAssignment.
   *
   * Setzt das Ablaufdatum eines Assignment neu.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAssignment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAssignment(
    params: {
      id: number;
      body?: UpdateAssignmentRequest
    },
    context?: HttpContext
  ): Observable<GenericResponseEntitlement> {
    return this.updateAssignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenericResponseEntitlement>): GenericResponseEntitlement => r.body)
    );
  }

  /** Path part for operation `entitlementRenewEntitlement()` */
  static readonly EntitlementRenewEntitlementPath = '/api/modules/entitlement-ext/entitlement-service/entitlement/{entitlementId}/renew';

  /**
   * RenewEntitlement.
   *
   * Verlängert ein Entitlement um die Laufzeit.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renewEntitlement()` instead.
   *
   * This method doesn't expect any request body.
   */
  renewEntitlement$Response(
    params: {
      entitlementId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GenericResponseEntitlement>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementRenewEntitlementPath, 'patch');
    if (params) {
      rb.path('entitlementId', params.entitlementId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenericResponseEntitlement>;
      })
    );
  }

  /**
   * RenewEntitlement.
   *
   * Verlängert ein Entitlement um die Laufzeit.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renewEntitlement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  renewEntitlement(
    params: {
      entitlementId: number;
    },
    context?: HttpContext
  ): Observable<GenericResponseEntitlement> {
    return this.renewEntitlement$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenericResponseEntitlement>): GenericResponseEntitlement => r.body)
    );
  }

  /** Path part for operation `entitlementDisableAutoRenew()` */
  static readonly EntitlementDisableAutoRenewPath = '/api/modules/entitlement-ext/entitlement-service/entitlement/{entitlementId}/disable-auto-renew';

  /**
   * DisableAutoRenew.
   *
   * Verlängert ein Entitlement um die Laufzeit.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disableAutoRenew()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  disableAutoRenew$Response(
    params: {
      entitlementId: number;
      body?: CancelDateRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GenericResponseEntitlement>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementDisableAutoRenewPath, 'patch');
    if (params) {
      rb.path('entitlementId', params.entitlementId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenericResponseEntitlement>;
      })
    );
  }

  /**
   * DisableAutoRenew.
   *
   * Verlängert ein Entitlement um die Laufzeit.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disableAutoRenew$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  disableAutoRenew(
    params: {
      entitlementId: number;
      body?: CancelDateRequest
    },
    context?: HttpContext
  ): Observable<GenericResponseEntitlement> {
    return this.disableAutoRenew$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenericResponseEntitlement>): GenericResponseEntitlement => r.body)
    );
  }

  /** Path part for operation `entitlementAddAssignment()` */
  static readonly EntitlementAddAssignmentPath = '/api/modules/entitlement-ext/entitlement-service/entitlement/{entitlementId}/assignment';

  /**
   * AddAssignment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAssignment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addAssignment$Response(
    params: {
      entitlementId: number;
      body?: EntitlementAssignment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GenericResponseEntitlement>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementAddAssignmentPath, 'put');
    if (params) {
      rb.path('entitlementId', params.entitlementId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenericResponseEntitlement>;
      })
    );
  }

  /**
   * AddAssignment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addAssignment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addAssignment(
    params: {
      entitlementId: number;
      body?: EntitlementAssignment
    },
    context?: HttpContext
  ): Observable<GenericResponseEntitlement> {
    return this.addAssignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenericResponseEntitlement>): GenericResponseEntitlement => r.body)
    );
  }

  /** Path part for operation `entitlementDeleteAssignment()` */
  static readonly EntitlementDeleteAssignmentPath = '/api/modules/entitlement-ext/entitlement-service/entitlement/{entitlementId}/assignment/{assignmentId}';

  /**
   * DeleteAssignment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAssignment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAssignment$Response(
    params: {
      entitlementId: number;
      assignmentId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GenericResponseEntitlement>> {
    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementDeleteAssignmentPath, 'delete');
    if (params) {
      rb.path('entitlementId', params.entitlementId, {});
      rb.path('assignmentId', params.assignmentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenericResponseEntitlement>;
      })
    );
  }

  /**
   * DeleteAssignment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAssignment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAssignment(
    params: {
      entitlementId: number;
      assignmentId: number;
    },
    context?: HttpContext
  ): Observable<GenericResponseEntitlement> {
    return this.deleteAssignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenericResponseEntitlement>): GenericResponseEntitlement => r.body)
    );
  }

}
