import { Action, createAction, props } from '@ngrx/store';

export const AddSuccessNotificationAction = createAction(
  '[UI.Notification] Add Info Notification',
  props<{ message: string; nextAction?: Action }>()
);

export const AddWarningNotificationAction = createAction(
  '[UI.Notification] Add Warning Notification',
  props<{ message: string; nextAction?: Action }>()
);

export const AddErrorNotificationAction = createAction(
  '[UI.Notification] Add Error Notification',
  props<{ message: string; title: string | undefined; nextAction?: Action }>()
);
