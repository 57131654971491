import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import * as NotificationActions from './notification.actions';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationEffects {
  private actions$ = inject(Actions);
  private matSnackBar = inject(ToastrService);

  successNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.AddSuccessNotificationAction),
        tap(() => 'show notification'),
        tap((action) => {
          this.matSnackBar.success(action.message, undefined, {
            progressBar: true,
            progressAnimation: 'decreasing',
          });
        })
      ),
    { dispatch: false }
  );

  errorNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.AddErrorNotificationAction),
        tap(() => 'show notification'),
        tap((action) => {
          this.matSnackBar.error(action.message, action.title, {
            progressBar: true,
            progressAnimation: 'decreasing',
          });
        })
      ),
    { dispatch: false }
  );
}
