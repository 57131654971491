<h1 mat-dialog-title *ngIf="data.headline">{{ data.headline }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.description"></p>
</div>
<div mat-dialog-actions align="center">
  <button mat-raised-button [mat-dialog-close]="false" cdkFocusInitial>
    Abbrechen
  </button>
  <button mat-raised-button color="warn" [mat-dialog-close]="true">
    Löschen
  </button>
</div>
