/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { BildungsloginService } from './services/bildungslogin.service';
import { BildungsloginAdminService } from './services/bildungslogin-admin.service';
import { EntitlementService } from './services/entitlement.service';
import { EntitlementAdminService } from './services/entitlement-admin.service';
import { KeycloakService } from './services/keycloak.service';
import { KeycloakHookService } from './services/keycloak-hook.service';
import { LoginService } from './services/login.service';
import { SocketService } from './services/socket.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    BildungsloginService,
    BildungsloginAdminService,
    EntitlementService,
    EntitlementAdminService,
    KeycloakService,
    KeycloakHookService,
    LoginService,
    SocketService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
