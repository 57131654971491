import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-key-value',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './key-value.component.html',
  styleUrls: ['./key-value.component.scss'],
})
export class KeyValueComponent {
  @Input()
  key: string | undefined;

  @Input()
  value: string | undefined | null;

  @Input()
  iconType = 'fa';

  @Input()
  icon: string | undefined;

  @Input()
  contentClass = 'mb-3';
}
