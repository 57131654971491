import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'ngx-moment';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ui-footer',
  standalone: true,
  imports: [CommonModule, MomentModule, RouterLink],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  today = new Date();
}
