import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'external-system-imprint',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {}
