import {
  Component,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
} from '@angular/forms';
import {
  BehaviorSubject,
  debounceTime,
  of,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import {
  compareObjects,
  hasValue,
} from '@external-system/modules/shared/utils';

@Component({
  selector: 'ui-base-form',
  standalone: true,
  imports: [CommonModule],
  template: ``,
})
export class BaseFormComponentComponent<T>
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input()
  public formControlName = '';

  @Input()
  public debounceTime = 0;

  @Input()
  public title = '';

  @Input()
  get control(): FormControl {
    return this._control as FormControl;
  }
  set control(control: AbstractControl<T>) {
    this._control = control;
    if (hasValue(control) && hasValue(control.value)) {
      this.value = control.value;
    }
  }

  @Input()
  public ngModel: any;

  @Output()
  public ngModelChange: EventEmitter<any> = new EventEmitter();

  @Output()
  public valueChange: EventEmitter<T> = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public value$: BehaviorSubject<T> = new BehaviorSubject<T>(undefined);

  protected _control: AbstractControl | undefined;

  @Input()
  get value(): T | undefined {
    return this._value;
  }

  set value(value: T) {
    of(undefined)
      .pipe(debounceTime(this.debounceTime), take(1))
      .subscribe(() => {
        if (!compareObjects(value, this._value)) {
          this._value = value;
          if (hasValue(this.control)) {
            this.control.patchValue(value, {
              emitEvent: true,
              onlySelf: false,
            });
          }
          this.valueChange.emit(value);
          this.value$.next(value);
        }
      });
  }

  @Input()
  public disabled = false;

  protected destroy$: Subject<boolean> = new Subject<boolean>();

  protected _value: T | undefined;

  constructor(
    @Optional() @Host() @SkipSelf() protected controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {
    if (hasValue(this.controlContainer)) {
      if (hasValue(this.formControlName)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.control = this.controlContainer.control.get(
          this.formControlName
        ) as AbstractControl;
      }
    }

    if (hasValue(this.control)) {
      this.control.valueChanges
        .pipe(debounceTime(this.debounceTime), takeUntil(this.destroy$))
        .subscribe((value) => {
          if (!compareObjects(value, this._value)) {
            this._value = value;
            this.valueChange.emit(value);
            this.value$.next(value);
          }
        });
    }
  }

  protected readonly hasValue = hasValue;
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onTouched = () => {};
  onChange = (value: any) => {
    this.value = value;
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
}
